<template>
  <main class="kb-main" id="kb-myclass-notice">
    <lxp-main-header :show-title="true" :show-back="true" :title="noticeTitle">
      <template v-slot:inner>
        <div class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button v-if="fileItems.length > 0" class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list">
                  <li v-for="(item, idx) in fileItems" :key="idx" class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" :title="item.fileNm"  @click.stop="downloadFile(item)">
                      <span class="dropdown-option-text">{{item.fileNm}}</span>
                      <span class="download-mb">{{ convertToStorageBytes(item.fileSz)}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{items.ntcTitle}}</h3>
          <p class="subtitle">{{ getDateFormat('yyyy.MM.dd hh:mm', items.regDt) }} 등록</p>
        </div>
        <div class="view-body min-component">
          <div class="view-content" v-html="items.ntcCn" />
          <div v-if="fileItems.length > 0" class="segment-box my-md-5">
            <strong class="text-lg">첨부파일</strong>
            <ul class="mt-lg-2">
              <li v-for="(item, idx) in fileItems" :key="idx">
                <a @click.stop="downloadFile(item)" class="kb-mouse-cursor">
                  <span class="text link-dark">{{item.fileNm}}</span>
                  <span class="text text-black-50 ps-md-3">{{ convertToStorageBytes(item.fileSz)}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_NOTICE, ACT_UPDATE_NOTICE_CNT} from '@/store/modules/help/help';
import {commonDownloadFile, getItem, getItems, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {convertToStorageBytes} from "@/assets/js/util";
import navigationUtils, {isInApp, isInAppIOS} from "@/assets/js/navigationUtils";
import {downloadAtch} from "@/assets/js/modules/help/help-common";

export default {
  name: 'HelpNoticeView',
  components: {LxpMainHeader},
  setup(){
    const noticeTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);

    const comNtcSn = computed(() => route.params.ntcSn);

    const getHelpNotice = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE}`,
          route.params.ntcSn
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          getHelpNoticeFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    getHelpNotice();

    const getHelpNoticeFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comNtcSn: comNtcSn.value
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const pageViewCnt = async () => {
      if (comNtcSn.value > 0) {
        await store.dispatch(`help/${ACT_UPDATE_NOTICE_CNT}`, {comNtcSn: comNtcSn.value});
      }
    };

    const downloadFile = (atch) => {
      if((isInApp && isInAppIOS) || navigationUtils.isTablet()) {
        commonDownloadFile(atch);
        //
        // if (atch.buketNm === 'pea.hrd.aws.prd.s3.contents') {
        //   winOpen(`https://lxpcms.kbstar.com/${atch.upldLoc}`, '_blank');
        // } else if (atch.buketNm === 'pea.hrd.aws.dev.s3.contents') {
        //   winOpen(`https://devlxpcms.kbstar.com/${atch.upldLoc}`, '_blank');
        // }
      } else {
        downloadAtch(atch);
      }
    }

    onMounted(() => {
      pageViewCnt();
    });

    watch(() => route.params.ntcSn, ()=>{
      getHelpNotice();
    });

    return {
      noticeTitle,
      items,
      fileItems,
      showAttachments,
      getDateFormat,
      downloadFile,
      downloadAtch,
      convertToStorageBytes,
    }

  }
}
</script>

<style type="text/css">
.view-content p{
  font: unset;
}
</style>